<template>
  <div class="allcontens">
    <div class="content">
      <van-field disabled v-model="name" required name="name" label="持卡人" input-align="right" placeholder="请填写持卡人"
        :rules="[{ required: true, message: '请填写持卡人' }]" />
      <van-field disabled v-model="bankId" @blur="bankCodeBlur" :formatter="formatter" required name="bankId" label="银行卡号"
        input-align="right" placeholder="请填写银行卡号" :rules="[{ required: true, message: '请填写银行卡号' }]" />
      <van-field disabled placeholder="请选择开卡银行" :value="bankCode" input-align="right" name="bankCode" required readonly
        label="开卡银行" error :rules="[{ required: true, message: '请选择开卡银行' }]">
        <template #input>
          <van-dropdown-menu>
            <van-dropdown-item disabled v-model="bankCode" :options="bankCodes" ref="item" />
          </van-dropdown-menu>
        </template>
      </van-field>
      <van-field disabled v-model="mobile" required name="mobile" label="手机号" input-align="right" placeholder="请填手机号"
        :rules="[{ required: true, message: '请填手机号' }]" />
      <van-field v-model="verCode" required name="verCode" label="验证码" input-align="right" placeholder="请填验证码"
        :rules="[{ required: true, message: '请填验证码' }]">
        <template #button>
          <van-button size="small" plain hairline @click="send" :disabled="second > 0">{{ second > 0 ? second + ' 秒':
          '发送验证码' }}</van-button>
        </template>
      </van-field>
      <!-- 2022.07.01 只有已经在通联绑定后才支持解绑手机号 -->
      <div class="unbind" v-if="bindMobile">
        <span @click="SendUnBindCode">解绑手机号</span>
      </div>
      <p class="tips margin-top-20">提示：银行卡一旦绑定， 不支持重新绑卡。</p>
      <p class="agreement" v-if="agreementInfo.authorizationAgreement">
        <van-checkbox v-model="agreement1"></van-checkbox> <span class="agreement-text">本人已充分阅读、理解并同意
          <a :href="agreementInfo.authorizationAgreement">《用户授权协议》</a>，授权<b style="color: #666;">中国银联</b>
          获取本人个人信息，包括<b style="color: #666;">姓名、证件类型、证件号码、手机号、所选银行卡号</b>
        </span>
      </p>
      <p class="agreement" v-if="agreementInfo.serviceAgreement">
        <van-checkbox v-model="agreement2"></van-checkbox> <span class="agreement-text">本人已阅读并同意
          <a :href="agreementInfo.serviceAgreement">《{{agreementInfo.serviceAgreementTitle}}》</a></span>
      </p>
      <div style="margin: 16px;" class="row justify-center">
        <van-button round type="info" size="small" class="margin-top-50 next-btn" @click="submit" native-type="submit">
          提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import { getMultPolicy } from '@/api/extendedWarranty'
import { getSelectBankCode, bindBankCard, queryCardBin, confirmBindBankCard, getBankAgreement } from '@/api/sign'
export default {
  data() {
    return {
      payWay: 'wxQR',
      bodyNo: '',
      name: '',
      bankId: '',
      bankCode: '',
      // bankBranch: '',
      bankCodes: [],
      mobile: '',
      verCode: '',
      second: 60,
      constSecond: 60,
      insuranceNo: '',
      applyOrderNo: '', // 申请绑卡生成订单号
      bindMobile: false, // 是否已经绑定
      agreement1: false, // 协议1
      agreement2: false, // 协议2
      agreementInfo: {}
    }
  },
  async created() {
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      // 微信客户端
      this.payWay = 'wxH5'
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      // 支付宝客户端
      this.payWay = 'alipayH5'
    } else {
      // 其他浏览器
      this.payWay = 'wxH5'
    }
    this.insuranceNo = this.$route.query.insuranceNo
    const resp = await getMultPolicy({ insuranceNo: this.insuranceNo, payWay: this.payWay })
    if (!resp.success) {
      this.$toast.fail(resp.respMag)
      return
    }
    storage.setItem('info', resp.content)
    storage.setItem('bodyNo', resp.content.bodyNo)
    // 保存订单及银行卡和个人信息
    const info = resp.content
    if (info) {
      this.bodyNo = info.bodyNo
      this.bankId = info.bankCardNo
      this.bankCode = info.bankCode
      this.mobile = info.contactPhone
      this.name = info.customerName
      this.bindMobile = info.bindMobile
    }
    this.getBankAgreementFun()
    // 获取支持的开户行
    const res = await getSelectBankCode()
    if (res.success) {
      const data = JSON.stringify(res.content).replace(/label/g, 'text')
      this.bankCodes = JSON.parse(data)
    }
    this.time()
  },
  methods: {
    // 获取服务协议
    getBankAgreementFun() {
      getBankAgreement({
        bankCode: this.bankCode
      }).then(res => {
        if (res.success) {
          console.log(res)
          this.agreementInfo = res.content
        }
      })
    },
    async send() {
      const params = {
        bodyNo: this.bodyNo,
        bankCardNo: this.bankId,
        bankCode: this.bankCode,
        bankCardHolder: this.name,
        cardCheck: 6
      }
      const res = await bindBankCard(params)
      if (!res.success) {
        this.$toast.fail(res.respMag);
        return
      }
      this.applyOrderNo = res.content.applyOrderNo
      storage.setItem('wechatBandSecond', this.constSecond)
      this.time()
    },
    time() {
      const _this = this
      const storageSecond = storage.getItem('wechatBandSecond')
      if (!storageSecond) {
        this.second = 0
        return
      }
      _this.second = storage.getItem('wechatBandSecond') || _this.constSecond
      _this.timer = setInterval(function () {
        _this.second = _this.second - 1
        if (_this.second > 0) {
          storage.setItem('wechatBandSecond', _this.second)
        } else {
          storage.setItem('wechatBandSecond', 0)
          clearInterval(_this.timer);
        }
      }, 1000)
    },
    async SendUnBindCode() {
      this.$router.push({ path: '/sign/unbind_code', query: { mobile: this.mobile } })
    },
    formatter(value) {
      // 过滤输入的数字
      return value.replace(/[^\d]/g, '');
    },
    async bankCodeBlur(e) {
      const val = e.target._value
      if (!val) return

      const res = await queryCardBin({ bankCardNo: val })
      if (!res.success) {
        this.$toast(res.respMag)
        return
      }
      this.bankCode = res.content.bankCode
    },
    async submit() {
      if (this.agreementInfo.authorizationAgreement && !this.agreement1) {
        this.$toast.fail('请勾选《用户授权协议》！')
        return
      }
      if (this.agreementInfo.serviceAgreement) {
        if (!this.agreement2) {
          this.$toast.fail(`请勾选《${this.agreementInfo.serviceAgreementTitle}》！`)
          return
        }
      }
      if (!this.applyOrderNo) {
        this.$toast.fail('请点击发送验证码！')
        return
      }
      if (!this.verCode || this.verCode.length !== 6) {
        this.$toast.fail('请填写6位数的验证码！')
        return
      }
      const params = {
        bodyNo: this.bodyNo,
        applyOrderNo: this.applyOrderNo,
        verCode: this.verCode,
        invoiceAddress: ''
      }
      const res = await confirmBindBankCard(params)
      if (!res.success) {
        this.$toast.fail(res.respMag)
        return
      }
      storage.clearItem('wechatBandSecond')
      res.success && this.$router.push({ path: '/sign/account', query: { applyOrderNo: res.content.applyOrderNo } })
    }
  }
}
</script>
<style scoped>
.borderbt {
  border-bottom: 1px solid #e4e7ed;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  font-size: 0.28rem;
}

.error {
  margin-left: 2rem;
  color: red;
  padding-top: 3px;
  /* padding-bottom: 0.05rem; */
  visibility: hidden;
}

.smsnext {
  margin: 1rem auto;
}

.unbind {
  display: flex;
  justify-content: flex-end;
  font-size: 0.3rem;
  margin-top: 0.2rem;
  color: #1989fa;
  margin-right: 0.2rem;
}

.bank-code {
  width: 70%;
  border: none;
  padding-left: 0.28rem;
  color: #757575;
  font-size: 0.28rem;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  background-image: url(../../assets/agreement/drop-down.svg);
  background-repeat: no-repeat;
  background-size: 0.3rem 0.3rem;
  background-position-x: 2.5rem;
  background-position-y: 0.06rem;
  /* background-position: 0rem 3rem; */
}

a {
  color: #0084fe;
  text-decoration: underline;
}

.content>>>.van-dropdown-menu__bar {
  height: auto;
  box-shadow: none;
}

.agreement {
  color: #999;
  padding: 0.1rem;
  font-size: 0.35rem;
  display: flex;
  justify-content: space-between;
  line-height: 0.6rem;
  align-items: flex-start;
}

.agreement-text {
  width: 75vw;
}
</style>
